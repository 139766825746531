import { useEffect } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa'

export default function GoogleMaps() {
    const apiKey = 'AIzaSyCfMvkehOqXanwfqCf3S_0_MOJPu5uUnXU'

    useEffect(() => {
        const polyfill = document.createElement('script');
        polyfill.src = 'https://polyfill.io/v3/polyfill.min.js?features=default';
        document.head.appendChild(polyfill);

        const googleapis = document.createElement('script');
        googleapis.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=&v=weekly`;
        googleapis.async = true;
        googleapis.onload = initMap;
        document.body.appendChild(googleapis);

        return () => {
            document.head.removeChild(polyfill);
            document.body.removeChild(googleapis);
        }
    }, []);

    const initMap = () => {
        let map: google.maps.Map;
        const center: google.maps.LatLngLiteral = { lat: 47.354944520639606, lng: 7.905519736262436 };

        map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
            center,
            zoom: 16,
        });
        const marker = new google.maps.Marker({
            position: center,
            map: map,
        });
        marker.setLabel({
            color: '#000',
            fontWeight: 'bold',
            fontSize: '14px',
            text: 'Alternativmedizin Praxis Olten',
        });
    }

    return (
        <div className="location">
            <h2>Standort</h2>
            <div className="icon-text-inline">
                <FaMapMarkerAlt />
                <a href="https://www.google.ch/maps/place/Dorn+%26+Craniosacral+Therapie/@47.3548101,7.9032452,17z/data=!3m1!4b1!4m5!3m4!1s0x47903171548d55ab:0xd178c7cd7c59509a!8m2!3d47.3548101!4d7.9054339?shorturl=1" target="_blank" rel="noreferrer">
                    <address>
                        Amthausquai 33, 4600 Olten
                    </address>
                </a>
            </div>
            <div id="map"></div>
        </div>
    );
}