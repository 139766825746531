import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './layout/Header'
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import Products from './pages/Products';
import TermsAndConditions from './pages/TermsAndConditions';
import Sidenav from './layout/Sidenav';
import ScrollToTop from './components/ScrollToTop';
import Appointment from './pages/Appointment';

export default function App() {
  return (
    <div id="app">
      <div id="content">
        <Header />
        <BrowserRouter>
          <ScrollToTop />
          <Navbar />
          <Sidenav />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services" element={<Services />} />
            <Route path="/products" element={<Products />} />
            <Route path="/appointment" element={<Appointment />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
      <div id="copyright">
        <p>&copy; 2024 Alternativmedizin Praxis Olten</p>
      </div>
    </div>
  );
}