import React from "react";
import { Link } from 'react-router-dom';
import { FaTimes, FaHome, FaUser, FaShoppingCart, FaMapMarkerAlt, FaCalendarAlt, FaInstagram } from 'react-icons/fa';
import { GiSpineArrow } from 'react-icons/gi';
import { RiFilePaper2Fill } from 'react-icons/ri';
import logo from '../assets/images/logo.png';

export default function Sidenav() {

    const close = () => {
        const sidenav = document.getElementById("sidenav");
        if (sidenav !== null) {
            sidenav.style.width = "0";
        }
    }

    return (
        <div id="sidenav">
            <button type="button" className="close" onClick={close}><FaTimes /></button>
            <Link to="/" onClick={close}><img src={logo} className="logo" alt="Alternativmedizin Praxis Olten" /></Link>
            <div className="icon-text-inline">
                <FaHome />
                <Link to="/" onClick={close}>Home</Link>
            </div>
            <div className="icon-text-inline">
                <FaUser />
                <Link to="/about" onClick={close}>Über mich</Link>
            </div>
            <div className="icon-text-inline">
                <GiSpineArrow />
                <Link to="/services" onClick={close}>Craniosacral-Therapie</Link>
            </div>
            <div className="icon-text-inline">
                <FaShoppingCart />
                <Link to="/products" onClick={close}>Produkte</Link>
            </div>
            <div className="icon-text-inline">
                <FaMapMarkerAlt />
                <Link to="/contact" onClick={close}>Kontakt</Link>
            </div>
            <div className="icon-text-inline">
                <FaCalendarAlt />
                <a href="https://www.studiobookr.com/dorn-craniosacral-therapie-61135" onClick={close} rel="noreferrer" target="_blank">Termin online buchen</a>
            </div>
            <div className="icon-text-inline">
                <RiFilePaper2Fill />
                <Link to="/terms-and-conditions" onClick={close}>Datenschutz &amp; AGB</Link>
            </div>
            <div className="icon-text-inline instagram">
                <FaInstagram />
                <a href="https://www.instagram.com/dorn.craniosacral.therapie/?hl=de" target="_blank" rel="noreferrer">Instagram</a>
            </div>
        </div>
    );
}