import React from 'react';

export default function Appointment() {

    const iFrameStyle = {
        border: '0px #ffffff none',
    };

    return (
        <div id="appointment">
            <div>
                <iframe src="https://patportal.cdm.ch/?location=26d12881-b443-481b-a2cd-a19545cb54bc&source=iframe" style={iFrameStyle} height="1340px" width="100%" name="" scrolling="no" frameBorder="0" allowFullScreen></iframe>
            </div>
        </div>
    );
}